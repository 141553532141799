import "core-js/modules/es.array.push.js";
export default {
  props: ['menuIndex'],
  data() {
    return {
      menus: [{
        id: 1,
        name: '首页',
        img: require('@/static/img/agent/footer/index.png'),
        imgCheck: require('@/static/img/agent/footer/index-check.png'),
        path: '/mobile/index'
      }, {
        id: 2,
        name: '实验室',
        img: require('@/static/img/agent/footer/caiwu.png'),
        imgCheck: require('@/static/img/agent/footer/caiwu-check.png'),
        path: '/mobile/room/roomList'
      }, {
        id: 3,
        name: '个人中心',
        img: require('@/static/img/agent/footer/my.png'),
        imgCheck: require('@/static/img/agent/footer/my-check.png'),
        path: '/mobile/user/userIndex'
      }],
      checkIndex: 0
    };
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
  },
  methods: {
    getIndex(index) {
      this.checkIndex = index;
    },
    tiaozhuan(index, path) {
      this.checkIndex = index;
      this.$root.useRouter.push(path);
    }
  }
};