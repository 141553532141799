import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e54e332"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex text-center menu-div-bo"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menus, (m, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: $event => $options.tiaozhuan(index, m.path),
      style: {
        "width": "33.3%"
      },
      class: _normalizeClass($data.checkIndex === index ? 'fontSize10gayA1' : 'fontSize10gayB1')
    }, [_createElementVNode("div", null, [_createElementVNode("img", {
      src: $data.checkIndex === index ? m.imgCheck : m.img
    }, null, 8, _hoisted_3)]), _createElementVNode("div", null, _toDisplayString(m.name), 1)], 10, _hoisted_2);
  }), 128))]);
}